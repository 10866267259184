<template>
    <div class="section" key="tatvorwurf">
        <div class="section-title">
            <div class="author"><img width="50" height="50" src="@/assets/images/rabier.png"></div>
            <h3>{{filteredTitle}}</h3>
            <p>Jetzt <a href="#">kostenlos</a> und <a href="#">unverbindlich</a> prüfen</p>
        </div>
        <div class="form-container">
            <p class="form-question">1. Was wird Ihnen vorgeworfen?</p>
            <div class="form-card">
            <input type="radio" v-model="user.accusation" name="speed-radio" value="Geschwindigkeitsverstoß" id="question-1-1">
            <label for="question-1-1" v-on:click="incrementChecked()"><span class="label-icon"><img src="@/assets/images/icons8-speedometer.svg"></span><span>Geschwindigkeitsverstoß</span></label>
            </div>
            <div class="form-card">
            <input type="radio" v-model="user.accusation" name="distance-radio" value="Abstandsverstoß" id="question-1-2">
            <label for="question-1-2" v-on:click="incrementChecked()"><span class="label-icon"><img src="@/assets/images/icons8-sedan.svg"></span><span>Abstandsverstoß</span></label>
            </div>
            <div class="form-card">
            <input type="radio" v-model="user.accusation" name="redlight-radio" value="Rotlichtverstoß" id="question-1-3">
            <label for="question-1-3" v-on:click="incrementChecked()"><span class="label-icon"><img src="@/assets/images/icons8-traffic-light.svg"></span><span>Rotlichtverstoß</span></label>
            </div>
            <div class="form-card">
            <input type="radio" v-model="user.accusation" name="mobile-drugs-parking-radio" value="Handy / Drogen / Parken" id="question-1-4">
            <label for="question-1-4" v-on:click="incrementChecked()"><span class="label-icon"><img src="@/assets/images/icons-beer.svg"></span><span>Handy / Drogen / Parken</span></label>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'Accusation',
  data() {
        return {
            utmParam: '',
            title: 'Jeder zweite verschickte Bußgeldbescheid ist fehlerhaft! Ist es Ihrer auch?'
        };
    },
  computed: {
    ...mapState(["user", "checked"]),
    filteredTitle: function() {
      let tempHeadline = this.title;

      if (this.utmParam != '' && this.utmParam) {
        switch (this.utmParam.toLowerCase()) {
          case 'geschwindigkeitsueberschreitung':
            tempHeadline = 'Geschwindigkeitsüberschreitung wirklich korrekt? Jetzt kostenlos prüfen.';
            break;
          case 'zuschnellgefahren':
            tempHeadline = 'Wirklich zu schnell gefahren? Jetzt kostenlos prüfen.';
            break;
          case 'poliscanspeed':
            tempHeadline = 'Poliscan Speed: Viele Messungen ungültig! Ihren Fall kostenlos prüfen.';
            break;
          case 'fahrverbot':
            tempHeadline = 'Fahrverbot vermeidbar? Jetzt kostenlos prüfen.';
            break;
          case 'fahrverbotumgehen':
            tempHeadline = 'Fahrverbot umgehen? Chancen jetzt kostenlos prüfen.';
            break;
          case 'punkteflensburg':
            tempHeadline = 'Punkte in Flensburg vermeidbar? Jetzt kostenlos prüfen.';
            break;
        
          default:
            tempHeadline = this.title;
            break;
        }
      }

      return tempHeadline;
    }
  },
  methods: {
    ...mapMutations([
      'incrementChecked'
    ]),
  },
  created(){
    this.utmParam = this.$route.query.utm_term;
  }
}
</script>